import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, timer } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';

import { AppConfig } from '../app.config';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private httpClient: HttpClient, public config: AppConfig) {}

  index(model: any, params = {}): Observable<any> {
    const options = {
      headers,
      params,
    };

    return this.httpClient.get<any>(this.config.config.API_URL + `api/${model}`, options).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  indexPost(endpoint: any, body = {}): Observable<any> {
    return this.httpClient.post<any>(this.config.config.API_URL + `api/${endpoint}`, body).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  customGetPDF(url: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        'Content-Type': 'application/json', // We send JSON
      }),
      responseType: 'text' as 'json', // We accept plain text as response.
    };

    return this.httpClient.get<any>(url, options).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  customPost(url: string, body = {}): Observable<any> {
    return this.httpClient.post<any>(url, body).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  save(model: string, request: object) {
    return this.httpClient.post(this.config.config.API_URL + `api/${model}`, request).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  update(model: string, request: object, id: any) {
    return this.httpClient.put(this.config.config.API_URL + `api/${model}/${id}`, request).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  updateWithoutId(model: string, request: object) {
    return this.httpClient.put(this.config.config.API_URL + `api/${model}`, request).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  uploadFile(model: string, request: any): Observable<any> {
    return this.httpClient
      .post(this.config.config.API_URL + `api/${model}`, request, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        retry({
          count: 2,
          delay: (_, retryCount) => timer(retryCount * 2000),
        }),
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  delete(model: string, id: any) {
    return this.httpClient.delete(this.config.config.API_URL + `api/${model}/${id}`).pipe(
      retry({
        count: 2,
        delay: (_, retryCount) => timer(retryCount * 2000),
      }),
      tap((p) => {}),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMsg = '';
    if (error.status) {
      if (error.status === 400) {
        errorMsg = 'Solicitud incorrecta';
      }
      if (error.status === 401) {
        errorMsg =
          'Token de acceso vencido o sin inicio de sesión. Favor de ingresar credenciales.';
      } else if (error.status === 403) {
        errorMsg = 'Acceso no autorizado.';
      } else if (error.status === 404) {
        if (error.error.hasOwnProperty('message')) {
          errorMsg = error.error.message;
        } else {
          errorMsg = 'Recurso no encontrado.';
        }
      } else if (error.status === 422) {
        if (error.error.hasOwnProperty('errors') && Object.keys(error.error.errors).length > 0) {
          errorMsg = '';
          Object.keys(error.error.errors).forEach((element) => {
            errorMsg += `${element}: ${error.error.errors[element]}, `;
          });
        } else if (error.error.hasOwnProperty('message')) {
          errorMsg = error.error.message;
        } else {
          errorMsg = 'Proceso inválido.';
        }
      } else if (error.status === 424) {
        errorMsg = 'Recurso externo no encontrado, inténtelo mas tarde.';
      } else if (error.status === 500) {
        errorMsg = 'Error del servidor: Favor de contactar al administrador del sistema.';
      } else if (error.status === 503) {
        errorMsg = 'Servicio no disponible.';
      } else {
        errorMsg = `Error no identificado: Favor de contactar al administrador del sistema.`;
      }
    } else {
      errorMsg = `Error interno: Favor de contactar al administrador del sistema.`;
    }

    return throwError(() => errorMsg);
  }
}
