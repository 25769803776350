{
  "name": "contaplex",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --aot --output-hashing=all --named-chunks",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build-prod": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng build --configuration=production --aot --output-hashing=all --named-chunks",
    "clear-version": "echo $null > src/environments/version.ts",
    "add-version": "echo \"export const VERSION = '$COMMIT_HASH';\" >> src/environments/version.ts",
    "generate-version": "npm run clear-version -s && npm run add-version -s",
    "start-docker": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve --host 0.0.0.0 --disable-host-check",
    "staging": "ng serve --configuration=staging"
  },
  "private": true,
  "dependencies": {
    "@amcharts/amcharts5": "^5.2.21",
    "@angular/animations": "^17.0.6",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/material": "^17.0.3",
    "@angular/material-moment-adapter": "^17.0.3",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "@auth0/angular-jwt": "^5.2.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/free-brands-svg-icons": "^6.5.1",
    "@fortawesome/free-regular-svg-icons": "^6.5.1",
    "@fortawesome/free-solid-svg-icons": "^6.5.1",
    "@popperjs/core": "^2.11.2",
    "@types/moment": "^2.13.0",
    "@types/xml2js": "^0.4.14",
    "ag-grid-angular": "^31.0.1",
    "ag-grid-community": "^31.0.1",
    "ag-grid-enterprise": "^31.0.1",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.1.3",
    "bot-contaplex": "https://contaplex.com/assets/libs/bot-contaplex-0.2.8.tgz",
    "idb": "^8.0.0",
    "jsoneditor": "^10.0.0",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.44",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-image-cropper": "^7.2.1",
    "ngx-toastr": "^18.0.0",
    "pusher-js": "^8.4.0-rc2",
    "rxjs": "~7.4.0",
    "sweetalert2": "^11.6.13",
    "timers": "^0.1.1",
    "tslib": "^2.3.0",
    "xml2js": "^0.6.2",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.9",
    "@angular/cdk": "^17.0.3",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@angular/localize": "^17.0.6",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^20.10.8",
    "css-loader": "^7.1.2",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "ng-packagr": "^17.0.2",
    "style-loader": "^4.0.0",
    "typescript": "~5.2.2"
  }
}
