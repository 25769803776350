// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  IS_EXPERIMENTAL: true,
  APP_VERSION: require('../../package.json').version + '-dev',
  API_URL: 'https://api-dev.dte.arisa.cl/',
  API_LOGIN: 'https://api-dev.dte.arisa.cl',
  CLIENT_ID: '2',
  CLIENT_SECRET: 'bxt2kEwdUTQjA6nGvsIP7rKcNU9OjnIVEA82H1B3',
  AGGRID_ACTION_2_BTN: 90,
  AGGRID_ACTION_3_BTN: 120,
  AGGRID_ACTION_4_BTN: 150,
  AGGRID_ACTION_5_BTN: 190,
  AGGRID_ACTION_6_BTN: 210,
  AGGRID_ACTION_7_BTN: 250,
  SHOW_BETA: true,
  VERSION_BETA: '1.0.0',
  PUSHER_ID: '1670773',
  PUSHER_KEY: 'cd63cdb1b31133e01d01',
  PUSHER_SECRET: '5b431910535a4e7913eb',
  PUSHER_CLUSTER: 'mt1',
  REGION: 'us-east-1',
  IDENTITY_POOL_ID: 'us-east-1:e30e886c-558b-4609-a421-aae17b317492',
  BOTID: 'JOIPGNQUC7',
  BOTALIASID: 'WAI7VHR5GE',
  LOCALEID: 'es_419',
  SESSIONID: 'testSession'
};
