import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { VERSION } from '@env/version';

@Injectable()
export class AppConfig {

  sassVariables: any;
  config: any;

  constructor() {
    this.sassVariables = this.getSassVariables();
    this.config = {
      name: 'Contaplex',
      title: 'Contaplex',
      API_URL: environment.API_URL,
      DEV:(environment.production) ? false : true,
      API_LOGIN: environment.API_LOGIN,
      CLIENT_ID: environment.CLIENT_ID,
      CLIENT_SECRET: environment.CLIENT_SECRET,
      AGGRID_ACTION_2_BTN: environment.AGGRID_ACTION_2_BTN,
      AGGRID_ACTION_3_BTN: environment.AGGRID_ACTION_3_BTN,
      AGGRID_ACTION_4_BTN: environment.AGGRID_ACTION_4_BTN,
      AGGRID_ACTION_5_BTN: environment.AGGRID_ACTION_5_BTN,
      AGGRID_ACTION_6_BTN: environment.AGGRID_ACTION_6_BTN,
      AGGRID_ACTION_7_BTN: environment.AGGRID_ACTION_7_BTN,
      VERSION: VERSION,
      colors: {
        main: this.sassVariables['main-color'],
        default: this.sassVariables['default-color'],
        dark: this.sassVariables['dark-color'],
        primary: this.sassVariables['primary-color'],
        info: this.sassVariables['info-color'],
        success: this.sassVariables['success-color'],
        warning: this.sassVariables['warning-color'],
        danger: this.sassVariables['danger-color'],
        sidebarBgColor: this.sassVariables['sidebar-bg-color'],
        gray: this.sassVariables['gray'],
        grayLight: this.sassVariables['gray-light']
      },
      messages: {
        sure_delete: '¿Estás seguro de borrar este registro?',
        added: 'Registro agregado exitosamente',
        deleted: 'Registro borrado exitosamente',
        edited: 'Registro actualizado exitosamente'
      },
      map: {
        lat: -30.714546,
        lng: -70.905587,
        mapTypeId: 'satellite',
        zoom: 16
      }
    }
  }

  getSassVariables() {
    const variables = {
      "main-color": "#242D3A",
      "default-color": "#fff",
      "dark-color": "#000",
      "primary-color": "#3960d1",
      "success-color": "#3bd076",
      "warning-color": "#f79a17",
      "danger-color": "#bf1725",
      "info-color": "#248dad",
      "sidebar-bg-color": "#2f353f",
      "gray": "#555",
      "gray-light": "#ccc"
    };

    return variables;
  }

}
